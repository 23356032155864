import { useEffect } from 'react';
import Chart from 'react-apexcharts';
import { FaChevronDown } from 'react-icons/fa';
import { BsArrowUpRightSquareFill } from 'react-icons/bs';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'

import { data, data2, data3, options, series } from '../../../helper/Chart';
import Landing_Contact from '../../landing/Landing_Contact';
import Landing_Choice from '../../landing/Landing_Choice';
import { useScroll } from '../../ScrollContext';
import { serviceprovider, whyserviceprovider } from '../../../helper/Constant';

// import { Bar, BarChart, CartesianGrid, Cell, Legend, Line, LineChart, Pie, PieChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'

const AnalysisTab = () => {
    const { aboutRef, servicesRef, contactRef } = useScroll();
    // const CustomTooltip = ({ active, payload, label }) => {
    //     if (active && payload && payload.length) {
    //         return (
    //             <div className="custom-tooltip" style={{
    //                 backgroundColor: "#fff",
    //                 padding: "10px",
    //                 border: "1px solid #ccc",
    //                 borderRadius: "10px"
    //             }}>
    //                 <p className="label" style={{ fontWeight: "bold" }}>{`${label}`}</p>
    //                 <p style={{ color: "#8884d8" }}>{`On-site ser. Req.: ${payload[0].value}`}</p>
    //                 <p style={{ color: "#82ca9d" }}>{`Virtual Con. Ser. Req.: ${payload[1].value}`}</p>
    //                 <p className="desc">{`Date: 22 August, 2019`}</p>
    //             </div>
    //         );
    //     }

    //     return null;
    // };

    useEffect(() => {
        window.scroll(0, 0)
    }, [])
    const handleDownloadClick = () => {
        const userAgent = window.navigator.userAgent;
        let redirectUrl = ''; // Initialize an empty URL

        if (/iPhone|iPad|iPod/i.test(userAgent)) {

            redirectUrl = 'https://apps.apple.com/us/app/allignx-home-services/id6621240477';

        } else if (/Windows/i.test(userAgent)) {

            console.log('window')
            redirectUrl = 'https://play.google.com/store/apps/details?id=com.allignx';
        }
        else if (/Mac/i.test(userAgent)) {
            console.log('macOS detected');
            redirectUrl = 'https://apps.apple.com/us/app/allignx-home-services/id6621240477'; // Redirect to the Mac 
        }
        else {
            redirectUrl = 'https://play.google.com/store/apps/details?id=com.allignx';
        }
        // Redirect to the appropriate URL
        window.location.href = redirectUrl;
    };
    const handleScroll = (id) => {
        const idName = String(id);
        const value = document.getElementById(idName).offsetTop;
        window.scrollTo(0, value - 100);
    };
    return (
        <>
            <div className='xl:px-48 lg:px-4  max-w-[1500px] lg:max-w-[768px]  mx-auto'>
                <div className='flex xl:flex-col justify-between  gap-10 '>
                    <div className='w-1/2  xl:w-full rounded-lg p-10 md:p-5 '>
                        <div>
                            <h1 className=' text-black text-[64px] md:text-5xl md:mb-10 font-readex_regular'>Grow Your Revenue With   <span className='text-primary'> Allignx</span></h1>
                            <div className='text-[#A6A6A6] text-lg'>Connect with thousands of customers looking for top-quality home services</div>
                            <div className='flex flex-wrap md:justify-center items-center gap-5'>
                                <div onClick={handleDownloadClick} className="flex  items-center bg-gradient-to-r from-[#A0A6FF] to-[#5A65FF] w-fit px-1 rounded-lg md:mt-8 mt-11 cursor-pointer">
                                    <div className="font-readex_medium text-[16px] text-[#fff] py-3.5 px-8">
                                        Sign Up Now
                                    </div>
                                    <BsArrowUpRightSquareFill className="text-[#fff] text-[40px]" />
                                </div>
                                <div onClick={() => handleScroll('work')} className="flex items-center bg-gradient-to-r from-[#A0A6FF] to-[#5A65FF] w-fit px-1 rounded-lg md:mt-8 mt-11 cursor-pointer">
                                    <div className="font-readex_medium text-[16px] text-[#fff] py-3.5 px-8">
                                        Get Started
                                    </div>
                                    <BsArrowUpRightSquareFill className="text-[#fff] text-[40px]" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='w-1/2 xl:w-full  rounded-lg pt-10 graphdiv'>
                        <div className=''>
                            <div className='md:flex flex-wrap items-center justify-between'>
                                <div className='flex w-full justify-between px-10'>
                                    <div>
                                        {/* <Menu>
                                        <MenuButton className="inline-flex items-center gap-2    text-[#4B465C] font-medium font-readex_medium text-lg ">
                                            Monthly Earning
                                            <FaChevronDown className="size-4" />
                                        </MenuButton>

                                        <MenuItems
                                            transition
                                            anchor="bottom end"
                                            className="w-52 origin-top-right rounded-xl border  bg-white p-1 text-sm/6 text-black transition duration-100 ease-out [--anchor-gap:var(--spacing-1)] focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0"
                                        >
                                            <MenuItem>
                                                <button className="group flex w-full items-center gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-white/10">

                                                    Edit
                                                    <kbd className="ml-auto hidden font-sans text-xs text-black/50 group-data-[focus]:inline">⌘E</kbd>
                                                </button>
                                            </MenuItem>
                                            <MenuItem>
                                                <button className="group flex w-full items-center gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-white/10">

                                                    Duplicate
                                                    <kbd className="ml-auto hidden font-sans text-xs text-black/50 group-data-[focus]:inline">⌘D</kbd>
                                                </button>
                                            </MenuItem>
                                            <div className="my-1 h-px bg-white" />
                                            <MenuItem>
                                                <button className="group flex w-full items-center gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-white/10">

                                                    Archive
                                                    <kbd className="ml-auto hidden font-sans text-xs text-black/50 group-data-[focus]:inline">⌘A</kbd>
                                                </button>
                                            </MenuItem>
                                            <MenuItem>
                                                <button className="group flex w-full items-center gap-2 rounded-lg py-1.5 px-3 data-[focus]:bg-white/10">

                                                    Delete
                                                    <kbd className="ml-auto hidden font-sans text-xs text-black/50 group-data-[focus]:inline">⌘D</kbd>
                                                </button>
                                            </MenuItem>
                                        </MenuItems>
                                    </Menu> */}
                                        <h1 className='text-[#4B465C] font-medium font-readex_medium text-lg mt-2'>Last 30 days</h1>
                                        <img className='mt-1' src="/assets/images/Group (4).png" alt="" />

                                    </div>
                                    <div>
                                        <span className='bg-[#5A65FF1A] text-primary font-readex_medium rounded px-3 py-1 text-sm'>+15.2%</span>
                                    </div>
                                </div>

                                {/* <div className='flex justify-start items-center gap-5 mt-3'>
                            <span className='text-[#4B465C] font-medium font-readex_medium text-[23px] '>$4,673</span>
                            <span className='bg-[#5A65FF1A] text-primary font-readex_medium rounded px-3 py-1 text-sm'>+15.2%</span>
                        </div> */}
                            </div>
                        </div>
                        <div>
                            <div className='md:'>

                                <div id="chart">
                                    <Chart options={options} series={series} type="line" height={350} />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>


                {/* old code with 2 graph */}
                {/* <div className='flex xl:flex-col justify-between  gap-10 '>
        <div className='w-1/2  xl:w-full rounded-lg p-10 md:p-5 ' style={{
            boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
        }}>
            <div className='flex md:flex-col justify-center gap-5'>
                <div className='md:flex flex-wrap items-center justify-between'>
                    <h1 className='text-[#4B465C] font-medium font-readex_medium text-lg'>Earning by category</h1>
                    <div className='flex justify-start items-center gap-5 mt-3'>
                        <span className='text-[#4B465C] font-medium font-readex_medium text-[23px] '>$4,673</span>
                        <span className='bg-[#5A65FF1A] text-primary font-readex_medium rounded px-3 py-1 text-sm'>+15.2%</span>
                    </div>
                </div>
                <div>

                    <div className='md:hidden'>
                        <ResponsiveContainer width={400} height={250}>
                            <BarChart data={data}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Bar dataKey="On-site ser. Req." fill="#304FFD" className='text-black' />
                                <Bar dataKey="Virtual Con. Ser. Req." fill="#304FFD1A" />
                            </BarChart>
                        </ResponsiveContainer>
                    </div>
                    <div className='md:block hidden'>
                        <ResponsiveContainer width='100%' height={250}>
                            <BarChart data={data}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Bar dataKey="On-site ser. Req." fill="#304FFD" className='text-black' />
                                <Bar dataKey="Virtual Con. Ser. Req." fill="#304FFD1A" />
                            </BarChart>
                        </ResponsiveContainer>
                    </div>
                </div>
            </div>
        </div>

        <div className='w-1/2 xl:w-full  rounded-lg p-10' style={{
            boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
        }}>
            <div className='flex md:flex-col justify-center gap-5'>
                <div>
                    <h1 className='text-[#4B465C] font-medium font-readex_medium text-lg'>Earning (Day-week-Month) </h1>
                    <div className='md:grid grid-cols-2 xs:grid-cols-1'>
                        <div>
                            <div className='text-[#4B465C] text-xs mt-3'>Day Earing</div>
                            <div className='flex justify-start items-center gap-5 mt-1'>
                                <span className='text-[#4B465C] font-medium font-readex_medium text-[23px] '>$250</span>
                                <span className='bg-[#5A65FF1A] text-primary font-readex_medium rounded px-3 py-1 text-sm'>+15.2%</span>
                            </div>
                        </div>

                        <div>
                            <div className='text-[#4B465C] text-xs mt-5'>Weekly Earing</div>
                            <div className='flex justify-start items-center gap-5 mt-1'>
                                <span className='text-[#4B465C] font-medium font-readex_medium text-[23px] '>$2,150</span>
                                <span className='bg-[#5A65FF1A] text-primary font-readex_medium rounded px-3 py-1 text-sm'>+08.2%</span>
                            </div>
                        </div>

                        <div>
                            <div className='text-[#4B465C] text-xs mt-5'>Monthly Earing</div>
                            <div className='flex justify-start items-center gap-5 mt-1'>
                                <span className='text-[#4B465C] font-medium font-readex_medium text-[23px] '>$60,350</span>
                                <span className='bg-[#5A65FF1A] text-primary font-readex_medium rounded px-3 py-1 text-sm'>+04.2%</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <div className='md:hidden'>
                        <PieChart width={400} height={300} margin={{ top: 0, right: 5, left: 8, bottom: 0 }}>
                            <Pie
                                data={data2}
                                dataKey="pv"
                                nameKey="name"
                                cx="50%"
                                cy="50%"
                                innerRadius={100}
                                outerRadius={130}
                                fill="#3E46FE"
                            // label
                            >
                                {data2.map((entry, index) => (<Cell key={`cell-${index}`} fill={entry.color} />))}
                            </Pie>
                            <text
                                x="50%"
                                y="50%"
                                textAnchor="middle"
                                dominantBaseline="middle"
                                fontSize="24px"
                                fontWeight="bold"
                                fill="#4B465C"
                            >
                                $60,350
                            </text>
                            <text
                                x="50%"
                                y="60%"
                                textAnchor="middle"
                                dominantBaseline="middle"
                                fontSize="18px"
                                fontWeight="bold"
                                fill="#3E46FE"
                            >
                                Total
                            </text>
                        </PieChart>
                    </div>

                    <div className='hidden md:block'>
                        <ResponsiveContainer width="100%" height={400}>
                            <PieChart width={400} height={300} margin={{ top: 0, right: 5, left: 8, bottom: 0 }}>
                                <Pie
                                    data={data2}
                                    dataKey="pv"
                                    nameKey="name"
                                    cx="50%"
                                    cy="50%"
                                    innerRadius={70}
                                    outerRadius={90}
                                    fill="#3E46FE"
                                // label
                                >
                                    {data2.map((entry, index) => (<Cell key={`cell-${index}`} fill={entry.color} />))}
                                </Pie>
                                <text
                                    x="50%"
                                    y="50%"
                                    textAnchor="middle"
                                    dominantBaseline="middle"
                                    fontSize="24px"
                                    fontWeight="bold"
                                    fill="#4B465C"
                                >
                                    $60,350
                                </text>
                                <text
                                    x="50%"
                                    y="60%"
                                    textAnchor="middle"
                                    dominantBaseline="middle"
                                    fontSize="18px"
                                    fontWeight="bold"
                                    fill="#3E46FE"
                                >
                                    Total
                                </text>
                            </PieChart>
                        </ResponsiveContainer>
                    </div>
                </div>
            </div>
        </div>
    </div> */}

                {/* <div className='w-1/2  xl:w-full rounded-lg p-10 md:p-5 mx-auto mt-10' style={{
        boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
    }}>
        <div className='flex md:flex-col justify-center gap-5'>
            <div className='md:flex flex-wrap items-center justify-between'>
                <h1 className='text-[#4B465C] font-medium font-readex_medium text-lg'>Earning by Ser. Req.</h1>
                <div className='flex justify-start items-center gap-5 mt-3'>
                    <span className='text-[#4B465C] font-medium font-readex_medium text-[23px] '>$4,673</span>
                    <span className='bg-[#5A65FF1A] text-primary font-readex_medium rounded px-3 py-1 text-sm'>+15.2%</span>
                </div>
            </div>
            <div>
                <div className='md:hidden'>
                    <LineChart data={data3}
                        width={430} height={250}
                        margin={{ top: 20, right: 30, left: 0, bottom: 0 }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip content={<CustomTooltip />} />
                        <Legend />
                        <Line type="monotone" dataKey="onsite" stroke="#8884d8" dot={{ r: 6 }} />
                        <Line type="monotone" dataKey="virtual" stroke="#82ca9d" dot={{ r: 6 }} />
                    </LineChart>
                </div>
                <div className='md:block hidden'>
                    <ResponsiveContainer width="100%" height={300}>
                        <LineChart data={data3}
                            width={430} height={250}
                            margin={{ top: 20, right: 30, left: 0, bottom: 0 }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" />
                            <YAxis />
                            <Tooltip content={<CustomTooltip />} />
                            <Legend />
                            <Line type="monotone" dataKey="onsite" stroke="#8884d8" dot={{ r: 6 }} />
                            <Line type="monotone" dataKey="virtual" stroke="#82ca9d" dot={{ r: 6 }} />
                        </LineChart>
                    </ResponsiveContainer>
                </div>
            </div>
        </div>
    </div> */}

                {/* Video Tutorial */}
                <h1 className='text-center text-black text-3xl py-20 font-readex_bold'>Video  <span className='text-primary'> Tutorial</span></h1>
                <div className="flex w-full justify-center md:px-4">
                    <video
                        controls
                        className="h-[500px] md:h-full rounded-[20px]"
                    >
                        <source
                            src="https://ax3woxv5ytmf.objectstorage.us-phoenix-1.oci.customer-oci.com/p/quE0Jq4IKy4PMAIy8tCmIAaXRiruonKhoiDdu328xkShPJozB97W21vDNKA-zqwS/n/ax3woxv5ytmf/b/bucket-20240811-1438/o/cVideos%2FAllignX_Providers.mp4"
                            type="video/mp4"
                        />
                        Your browser does not support the video tag.
                    </video>
                </div>


                {/* How it’s works */}
                <h1 id='work' className='text-center text-black text-3xl py-20 font-readex_bold'>How it’s  <span className='text-primary'> works </span></h1>
                <div className='grid grid-cols-4 xl:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-5'>
                    {serviceprovider?.map(({ title, description }) => (
                        <div className='text-[#A6A6A6] rounded-lg p-10 md:p-5' style={{
                            boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
                        }}>
                            <h2 className='text-black font-readex_semibold text-center ' >{title}</h2>
                            <div className='text-center text-sm'>{description}</div>
                        </div>
                    ))}
                </div>

                {/*Why AlignX ? */}
                <h1 className='text-center text-black text-3xl py-20 font-readex_bold'>Why <span className='text-primary'> AllignX ? </span></h1>
                <div className='grid grid-cols-4 xl:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-5'>

                    {whyserviceprovider?.map(({ title, description, img }) => (
                        <div className='text-[#A6A6A6] rounded-lg p-5 border border-[#EEEEEE]' >
                            <div className='flex gap-4  items-center'>
                                <div className='flex-none'>
                                    <img src={img} alt="image" width={36} height={36} className='size-9' />
                                </div>
                                <div>
                                    <h2 className='text-primary text-base font-readex_semibold ' > {title}</h2>
                                    <div className='text-sm mt-1.5'>{description}</div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className='mt-20'>
                <Landing_Choice img={`/assets/images/Untitled-2.png`} />
            </div>
            <div ref={contactRef} id='about'>
                <Landing_Contact />
            </div>
        </>
    )
}

export default AnalysisTab