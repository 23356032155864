import React, { lazy, Suspense, useEffect } from "react";
import p1 from "../images/p1.png";
import { BsArrowUpRightSquareFill } from "react-icons/bs";
import { useScroll } from "../Component/ScrollContext";
  
const Landing_Video = lazy(() =>
  import("../Component/landing/Landing_Video")
);
const Landing_Service = lazy(() =>
  import("../Component/landing/Landing_Service")
);
const Landing_Choose = lazy(() =>
  import("../Component/landing/Landing_Choose")
);
const Landing_Feature = lazy(() =>
  import("../Component/landing/Landing_Feature")
);
const Landing_FAQ = lazy(() => import("../Component/landing/Landing_FAQ"));
const Landing_WhyChoose = lazy(() =>
  import("../Component/landing/Landing_WhyChoose")
);
const Landing_About = lazy(() => import("../Component/landing/Landing_About"));
const Landing_Choice = lazy(() =>
  import("../Component/landing/Landing_Choice")
);
const Landing_Contact = lazy(() =>
  import("../Component/landing/Landing_Contact")
);

export default function LandingPage() {
  const { aboutRef, servicesRef, contactRef } = useScroll();
  useEffect(()=>{window.scroll(0,0)},[])
  const handleScroll = (id) => {
    const idName = String(id);
    const value = document.getElementById(idName).offsetTop;
    window.scrollTo(0, value - 100);
   };
  return (
    <div>
      <div className="flex lg:flex-col items-center justify-between gap-6 lg:px-4 xl:px-8 px-40 pb-16 xl:h-auto h-[calc(100vh-77px)]  pt-28">
        <div className="pt-12">
          <div className="font-readex_regular text-[#000] md:text-[26px] 1xl:text-[40px] text-[60px] capitalize 1xl:leading-relaxed leading-[89px]">
            Connecting you to <br className="1xl:hidden flex" />
            the{" "}
            <span className="font-readex_bold text-[#3E46FE]">
              Leading home <br className="1xl:hidden flex" /> service{" "}
            </span>{" "}
            providers <br className="1xl:hidden flex" /> at your fingertip
          </div>

          <div className="font-readex_regular text-[#8D8D8D] md:text-[14px] text-[16px] mt-4">
            AllignX is your one-stop solution for all home services, offering
            quick <br className="1xl:hidden flex" /> and dependable matches with
            professional providers. From electrical{" "}
            <br className="1xl:hidden flex" />
            and plumbing to TV mounting and roof repair, we ensure secure{" "}
            <br className="1xl:hidden flex" /> transactions and a seamless user
            experience. Trust us to connect with{" "}
            <br className="1xl:hidden flex" /> the best experts from the comfort
            of your home.
          </div>

          <div onClick={()=>handleScroll('video')} className="flex items-center bg-gradient-to-r from-[#A0A6FF] to-[#5A65FF] w-fit px-1 rounded-lg md:mt-8 mt-11 cursor-pointer">
            <div className="font-readex_medium text-[16px] text-[#fff] py-3.5 px-8">
              Learn More
            </div>
            <BsArrowUpRightSquareFill className="text-[#fff] text-[40px]" />
          </div>
        </div>

        {/* <img src={p1} alt="p1" className="lg:mt-16 -mt-28" /> */}
        <img
          src={p1}
          alt="p1"
          className="sm:w-[90%] lg:w-[70%] xl:w-[50%] 1xl:w-[600px] w-[700px] lg:mt-16 xl:-mt-10 -mt-28"
        />
      </div>
      
      <Suspense fallback={<div>Loading...</div>}>
      <div ref={servicesRef} >
        <Landing_Service />
      </div>
      <div>
        <Landing_Video />
      </div>
      <div ref={aboutRef}>
        <Landing_Choose />
      </div>
      <Landing_WhyChoose />
      <Landing_Feature />
      <Landing_FAQ />
      <Landing_Choice img={`/assets/images/Untitled-2 (1).png`}/>
      <div ref={contactRef}>
        <Landing_Contact />
      </div>
    </Suspense>
    </div>
  );
}
