import React, { useEffect, useState } from "react";
import AcceptableUsePolicy from "../Component/policies/Acceptable-Use-Policy";
import Californianotice from "../Component/policies/California-notice";
import CancellationandRefundPolicy from "../Component/policies/Cancellation-and-Refund-Policy";
import Codeofconduct from "../Component/policies/Code-Of-Conduct";
import Termofservices from "../Component/policies/Term";
import Termofuse from "../Component/policies/Term-of-use";
import Uspolicy from "../Component/policies/US-Compliant-Privacy-Policy";
import { Link, useSearchParams } from "react-router-dom";

export default function Policys() {
  const [tab, setTab] = useState("US Compliant");
  const [searchParams] = useSearchParams();
  const param1 = searchParams.get("key");
  console.log("param1", param1);
  useEffect(() => {
    if (param1 == "terms-for-service") {
      setTab("term");
    }
    if (param1 == "privacy-policy") {
      setTab("US Compliant");
    }
    if (param1 == "cancellation-policy") {
      setTab("refund");
    }
  }, []);
  return (
    <div className="min-h-screen py-40 max-w-[1500px]  mx-auto font-readex_regular px-4">
      <div className="flex overflow-auto  items-center gap-5 bg-primary/10  mx-auto  mb-10 px-5 py-4 rounded-full">
      <Link
        to={'/terms-policies'}
          className={`${
            tab === "US Compliant" &&
            "bg-primary text-white rounded-full font-readex_bold"
          } text-center text-black text-sm md:text-base md:px-2  px-3 py-2 cursor-pointer lg:text-nowrap `}
          onClick={() => setTab("US Compliant")}
        >
          US Compliant Privacy Policy
        </Link>
        <Link
        to={'/terms-policies'}
          className={`${
            tab === "California" &&
            "bg-primary text-white rounded-full font-readex_bold"
          } text-center text-black text-sm md:text-base md:px-2  px-3 py-2 cursor-pointer lg:text-nowrap `}
          onClick={() => setTab("California")}
        >
          California Consumer Privacy
        </Link>
        <Link
        to={'/terms-policies'}
          className={`${
            tab === "refund" &&
            "bg-primary text-white rounded-full font-readex_bold"
          } text-center text-black text-sm md:text-base md:px-2  px-3 py-2 cursor-pointer lg:text-nowrap `}
          onClick={() => setTab("refund")}
        >
          Cancellation and Refund Policy
        </Link>
        <Link
        to={'/terms-policies'}
          className={`${
            tab === "Conduct" &&
            "bg-primary text-white rounded-full font-readex_bold"
          } text-center text-black text-sm md:text-base md:px-2  px-3 py-2 cursor-pointer lg:text-nowrap `}
          onClick={() => setTab("Conduct")}
        >
          Code Of Conduct
        </Link>
        <Link
        to={'/terms-policies'}
          className={`${
            tab === "term" &&
            "bg-primary text-white rounded-full font-readex_bold"
          } text-center text-black text-sm md:text-base md:px-2  px-3 py-2 cursor-pointer lg:text-nowrap `}
          onClick={() => setTab("term")}
        >
          Terms Of Service Providers
        </Link>
        <Link
        to={'/terms-policies'}
          className={`${
            tab === "use" &&
            "bg-primary text-white rounded-full font-readex_bold"
          } text-center text-black text-sm md:text-base md:px-2  px-3 py-2 cursor-pointer lg:text-nowrap `}
          onClick={() => setTab("use")}
        >
          Terms Of Use
        </Link>
        <Link
        to={'/terms-policies'}
          className={`${
            tab === "Acceptable use policy" &&
            "bg-primary text-white rounded-full font-readex_bold"
          } text-center text-black text-sm md:text-base md:px-2 px-3 py-2  cursor-pointer lg:text-nowrap`}
          onClick={() => setTab("Acceptable use policy")}
        >
          Acceptable use policy
        </Link>
       
      </div>

      {tab === "Acceptable use policy" && <AcceptableUsePolicy />}
      {tab === "California" && <Californianotice />}
      {tab === "refund" && <CancellationandRefundPolicy />}
      {tab === "Conduct" && <Codeofconduct />}
      {tab === "term" && <Termofservices />}
      {tab === "use" && <Termofuse />}
      {tab === "US Compliant" && <Uspolicy />}
    </div>
  );
}
