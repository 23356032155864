import React from "react";
import google from "../../images/google.png";
import appStore from "../../images/appStore.png";
// import phone from "../../images/phone.png";

export default function Landing_Choice({img}) {
  return (
    <div className="choiceBg">
      <div className="lg:px-4 xl:px-8 px-40 pt-10 flex lg:flex-col items-center xl:gap-20 gap-40">
        <div>
          <div className="md:text-[24px] xl:text-[34px] text-[44px] text-[#DADBFF] font-readex_regular capitalize">
            The one app you need to Provide <br className="1xl:hidden flex" />{" "}
            <span className="text-[#FFF] font-readex_semibold">
              Any service For your Choice
            </span>
          </div>

          {/* <div className="text-[17px] text-[#FFFFFF] font-readex_regular mt-4">
            Allignx comes with cost calculator, a unique tool which allows you to easily <br className="1xl:hidden flex" /> create price estimation of
            your service.
          </div> */}

          <div className="flex sm:flex-col items-center gap-4 mt-14">
            <a href="https://play.google.com/store/apps/details?id=com.allignx">
            <img src={google} alt="google" />
            </a>
            <a href="https://apps.apple.com/us/app/allignx-home-services/id6621240477">
            <img src={appStore} alt="appStore" />
            </a>
          </div>
        </div>

        <img src={img} alt="phone" />
      </div>
    </div>
  );
}
