import React, { useState } from 'react'
import AnalysisTab from '../Component/portfolio/analysis/Analysis';
import Earning from '../Component/portfolio/earning/Earning';

export default function Analysis() {
    const [tab, setTab] = useState('analysis')

    return (
        <div className='min-h-screen py-40 md:py-32 mx-auto font-readex_medium  '>
            {/* <div className='flex justify-center items-center gap-5 bg-primary/10 w-fit mx-auto  mb-10 px-5 py-4 rounded-full'>
                <h1 className={`${tab === 'analysis' && "bg-primary text-white rounded-full font-readex_bold"} text-center text-black text-xl md:text-base md:px-2 px-3 py-2  cursor-pointer`} onClick={() => setTab('analysis')}>Market   Analysis </h1>
                <h1 className={`${tab === 'earning' && "bg-primary text-white rounded-full font-readex_bold"} text-center text-black text-xl md:text-base md:px-2  px-3 py-2 cursor-pointer `} onClick={() => setTab('earning')}>My   Earnings </h1>
            </div> */}
            {tab === "analysis" && <AnalysisTab />}
            {tab === 'earning' && <Earning />}
        </div>
    )
}
