import React, { useState } from "react";
import { HiMenu } from "react-icons/hi";
import { Link } from "react-router-dom";
import Drawer from "react-modern-drawer";
import { GrFormClose } from "react-icons/gr";
import { useLocation } from "react-router-dom";
import { useScroll } from "./ScrollContext";

import "react-modern-drawer/dist/index.css";
import logo from "../images/logo.png";

const Header = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [hover, setHover] = useState('')
  const [flag, setFlag] = useState(false)
  const { scrollToAbout, scrollToServices, scrollToContact } = useScroll();
const {pathname} = useLocation()
const path = pathname?.split('/')[1]

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };


  return (
    <div>
      <div className="flex fixed w-full bg-white z-50 top-0 justify-between items-center lg:px-4 xl:px-8 px-40 py-3 border-b border-[#d9dbe9]">
        <Link to="/">
          <img
            src={logo}
            alt="logo"
            className="w-[77px] md:w-[60px] md:h-[53px] h-[73.42px]"
          />
        </Link>
        <HiMenu
          className="text-[21px] cursor-pointer md:flex hidden"
          onClick={() => setIsDrawerOpen(true)}
        />

        <div className="md:hidden flex lg:gap-10 gap-14 font-readex_regular">
          <Link
            to="/"
            className={`${hover !== 'Home' && flag ? 'blur-sm' : 'blur-0'} duration-300 `}
            onMouseEnter={() => { setHover("Home"); setFlag(true) }}
            onMouseLeave={() => {  setFlag(false) }}
          >
            Home
          </Link>
          <Link
            to="/service-provider"
            className={`${hover !== 'service-provider' && flag ? 'blur-sm' : 'blur-0'} duration-300 `}
            onMouseEnter={() => { setHover("service-provider"); setFlag(true) }}
            onMouseLeave={() => {  setFlag(false) }}
          >
            Service-provider
          </Link>
          {pathname == '/' &&
          
          <Link
            to="#"
            onMouseEnter={() => { setHover("About"); setFlag(true) }}
            onMouseLeave={() => {  setFlag(false) }}
            onClick={(e) => {
              e.preventDefault();
              scrollToAbout();
            }}
            className={`${hover !== 'About' && flag ? 'blur-sm' : 'blur-0'} duration-300 `}
          >
            About
          </Link>
          }
          {pathname == '/' &&
          
          <Link
            to="#"
            onMouseEnter={() => { setHover("Services"); setFlag(true) }}
            onMouseLeave={() => {  setFlag(false) }}
            onClick={(e) => {
              e.preventDefault();
              scrollToServices();
            }}
            className={`${hover !== 'Services' && flag ? 'blur-sm' : 'blur-0'} duration-300 `}
          >
            Services
          </Link>
          }
          {
           ( pathname == '/' || pathname == '/service-provider') &&
          <Link
            to="#"
            onMouseEnter={() => { setHover("Contact us"); setFlag(true) }}
            onMouseLeave={() => {  setFlag(false) }}
            onClick={(e) => {
              e.preventDefault();
              scrollToContact();
            }}
            className={`${hover !== 'Contact us' && flag ? 'blur-sm' : 'blur-0'} duration-300 `}
          >
            Contact us
          </Link>
}
        </div>
      </div>

      <Drawer
        open={isDrawerOpen}
        onClose={toggleDrawer}
        direction="right"
        className="chatDrawer"
      >
        <GrFormClose
          className="text-[24px] absolute right-3 top-5"
          onClick={toggleDrawer}
        />
        <div className="mt-5 w-full px-3">
          <img src={logo} alt="logo" className="size-1/3 " />

          <div className="flex flex-col gap-4 mt-8 font-readex_regular">
            <Link to="/" onClick={() => setIsDrawerOpen(false)}>
              Home
            </Link>
            <Link to="/service-provider">Service-provider</Link>
            {pathname == '/' &&
            <Link
              to="#"
              onClick={(e) => {
                e.preventDefault();
                setIsDrawerOpen(false);
                scrollToAbout();
              }}
            >
              About
            </Link>
}
            {pathname == '/' &&
            <Link
              to="#"
              onClick={(e) => {
                e.preventDefault();
                scrollToServices();
                setIsDrawerOpen(false);
              }}
            >
              Services
            </Link>
}
{
           ( pathname == '/' || pathname == '/service-provider') &&
            <Link
              to="#"
              onClick={(e) => {
                e.preventDefault();
                scrollToContact();
                setIsDrawerOpen(false);
              }}
            >
              Contact us
            </Link>}
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default Header;
