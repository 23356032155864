export const serviceprovider = [
    {
        title: 'Sign Up ',
        description: 'Create your profile in minutes.'
    },
    {
        title: ' Create Service Request',
        description: 'Choose the service category and create your service request at your preferred date and time'
    },
    {
        title: 'Get Matched ',
        description: 'With trusted service provider around you'
    },
    {
        title: 'Link Your Account',
        description: 'Add your bank account details in the Bank Details section to receive timely payments'
    },
    {
        title: 'Start the Service',
        description: 'Get an agreement on the final service charge and start the service after verifying the One Time Password (OTP) from the customer'
    },
    {
        title: 'Complete the Service',
        description: 'Complete the service after verifying the One Time Password (OTP) from the customer'
    },
    {
        title: ' Get Paid',
        description: 'Receive the agreed upon service charge through AllignX app'
    },
    {
        title: 'Rate you Customer',
        description: 'Provide the rating and feedback to the customer'
    },
]

export const whyserviceprovider=[
    {
        title:'Easy booking',
        description:"Book Your Home Service With Just A Few Clicks",
        img:'/assets/svg/people.svg'
    },
    {
        title:'Verified Professionals',
        description:"Connect With Trusted And Background Checked Service Providers",
        img:'/assets/svg/wallet.svg'
    },
    {
        title:'Transparent Pricing',
        description:"Know The Cost Upfront With No Hidden Fees",
        img:'/assets/images/time.png'
    },
    {
        title:'Customer Support',
        description:"We Are Available To Assist You With Any Queries Or Issues",
        img:'/assets/images/lifebuoy.png'
    },
]
export const faqs = [
    {
      question: "What services does AllignX provide?",
      answer:
        "AllignX is a platform that connects customers with the service providers in the neighborhood. These service providers offer a wide range of home services, including AC heating, AC cooling, electrical work, plumbing, handyman tasks, furniture assembly, landscaping, TV mounting, roof repair, appliance repair, swimming pool maintenance, and other miscellaneous services.",
    },
    {
      question: "How does AllignX work?",
      answer:
        "Simply download our app, select the service you need, answer a few questions to specify your requirements, and AllignX will match you with a qualified service provider in your area. You can then review their profile, estimated cost, and schedule the service at your convenience.",
    },
    {
      question: "How do you ensure the quality of service providers?",
      answer:
        "All service providers on our platform undergo a thorough vetting process, including background checks, certification verification, and customer reviews. We aim to connect you with only the best professionals to ensure high-quality service.",
    },
    {
      question: "Is my personal information safe with AllignX?",
      answer:
        "Yes, your personal information is secure with us. We use industry-standard encryption and security measures to protect your data and ensure a safe transaction process",
    },
    {
      question: "How is the cost of a service determined?",
      answer:
        "The cost of a service is determined based on the specific requirements of your job, including the complexity, time, and materials needed. You will receive an estimated cost upfront before confirming the service.",
    },
    {
      question: "Can I cancel a service request?",
      answer:
        "Yes, you can cancel a service request through the app. Please check our cancellation policy for details on any applicable fees or timeframes.",
    },
    {
      question: "Can I reschedule a service request?",
      answer:
        "No, you cannot reschedule a service request through the app. You can only cancel a service request because once you reschedule Service Provider need to be assigned again to your request.",
    },
    {
      question: "What if I am not satisfied with the service?",
      answer:
        "Customer satisfaction is our top priority. If you are not satisfied with the service provided, please contact our customer support team within 24 hours of the service completion, and we will work to resolve the issue.",
    },
    {
      question: "How do I pay for the service?",
      answer:
        "Payments are made securely through the app. You only pay once the service is completed to your satisfaction and you have provided the OTP issued to you when the service provider started the work.",
    },
    {
      question: "Do you offer emergency services?",
      answer:
        "Yes, we offer emergency services for certain categories. You can specify the urgency when you make your service request.",
    },
    {
      question: "How can I contact customer support?",
      answer:
        "You can contact our customer support team through the app or via email. We are here to assist you with any questions or issues you may have.",
    },
    {
      question: "What areas do you serve?",
      answer:
        "We currently serve a wide range of areas. You can enter your address in the app to see the available service providers in your area.",
    },
  ];