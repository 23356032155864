import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { BsArrowUpRightSquareFill } from "react-icons/bs";
import { toast, Toaster } from "sonner"; 

const ContactSchema = Yup.object().shape({
  fullName: Yup.string().required("Name is required"),
  fromEmail: Yup.string().email("Invalid email").required("Email is required"),
  body: Yup.string().required("Message is required"),
});

export default function Landing_Contact() {
  // Handle form submission
  const handleSubmit = async (values, { resetForm }) => {
    try {
      const response = await axios.post(
        "https://wapi.allignx.com/api/v1/public/email/contact-us/send/message",
        values
      );
      console.log("response", response);
      toast.success("Message sent successfully!"); 
      resetForm(); 
    } catch (error) {
      resetForm();
      toast.error("Failed to send message. Please try again.");
    }
  };

  return (
    <div className="contactBg">
      <Toaster position="bottom-right" richColors/> 
      <div className="lg:px-4 xl:px-8 px-72 py-24">
        <div className="md:text-[24px] xl:text-[34px] text-[44px] text-[#000000] font-readex_regular text-center">
          Contact{" "}
          <span className="text-[#3E46FE] font-readex_semibold">Us</span>
        </div>

        <div className="md:text-[15px] text-[17px] text-[#8D8D8D] font-readex_regular text-center mt-2">
          We love to hear from you guys! Whether it is feedback for the app,
          ideas, anything!
        </div>

        <Formik
          initialValues={{
            fullName: "",
            fromEmail: "",
            body: "",
          }}
          validationSchema={ContactSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form className="flex flex-col items-center gap-7 mt-20 w-full">
              <div className="flex md:flex-col w-full gap-10">
              <div className="w-full">
                <div className="text-[16px] text-[#333333] font-readex_regular">
                  Your Name
                </div>
                <Field
                  type="text"
                  name="fullName"
                  placeholder="Enter your name"
                  className="bg-[#F5F6FF] text-[16px] outline-none font-readex_regular w-full px-6 py-4 mt-2 rounded-2xl"
                />
                <ErrorMessage
                  name="fullName"
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>

              <div className="w-full">
                <div className="text-[16px] text-[#333333] font-readex_regular">
                  Email Address
                </div>
                <Field
                  type="text"
                  name="fromEmail"
                  placeholder="Enter your email"
                  className="bg-[#F5F6FF] text-[16px] outline-none font-readex_regular w-full px-6 py-4 mt-2 rounded-2xl"
                />
                <ErrorMessage
                  name="fromEmail"
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>
              </div>
              <div className="w-full">
                <div className="text-[16px] text-[#333333] font-readex_regular">
                  Message
                </div>
                <Field
                  as="textarea"
                  name="body"
                  rows={5}
                  placeholder="Send Message"
                  className="bg-[#F5F6FF] text-[16px] outline-none font-readex_regular w-full px-6 py-4 mt-2 rounded-2xl"
                />
                <ErrorMessage
                  name="body"
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>

              <button
                type="submit"
                className="flex items-center justify-center cursor-pointer bg-gradient-to-r from-[#A0A6FF] to-[#5A65FF] w-fit px-1 rounded-lg mt-11"
                disabled={isSubmitting}
              >
                <div className="font-readex_medium text-[16px] text-[#fff] py-3.5 px-6">
                  {isSubmitting ? "Sending..." : "Send Message"}
                </div>
                <BsArrowUpRightSquareFill className="text-[#fff] text-[40px]" />
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}
